<template>
    <div class="media-content">
        <div :class="[
            'media-content__container', 
            odd ? 'media-content__container--reverse' : '',
            'media-content__container--' + color
            // odd ? 'media-content__container--green' : 'media-content__container--orange'
        ]">
            <div class="media-content__image-container">
                <picture>
                    <source :srcset="image" type="image/webp">
                    <img :src="image" :alt="imageAlt" class="media-content__image">
                </picture>
            </div>
            <div class="media-content__content-container">
                <div class="media-content__title title title--m">
                    {{ title }}
                </div>
                <div v-if="text" class="media-content__text text">
                    {{ text }}
                </div>
                <div v-if="label" class="media-content__link">
                    <a :href="link" class="media-content__button button--arrow">
                        {{ label }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
    id: Number,
    title: String,
    text: String,
    image: String,
    imageAlt: String,
    label: String,
    link: String,
    color: String
});

const odd = computed(() => props.id! % 2 !== 0);
</script>