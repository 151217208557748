import { createApp } from 'vue';
import Courses from './pages/Courses.vue';
import Vacancies from './pages/Vacancies.vue';
import Companies from './pages/Companies.vue';

if (document.getElementById('courses-vue-app') != null && document.getElementById('vacancies-vue-app') == null && document.getElementById('companies-vue-app') == null) {
    const coursesApp = createApp(Courses);
    coursesApp.mount('#courses-vue-app');
}

if (document.getElementById('vacancies-vue-app') != null && document.getElementById('courses-vue-app') == null && document.getElementById('companies-vue-app') == null) {
    const vacanciesApp = createApp(Vacancies);
    vacanciesApp.mount('#vacancies-vue-app');
}

if (document.getElementById('companies-vue-app') != null && document.getElementById('courses-vue-app') == null && document.getElementById('vacancies-vue-app') == null) {
    const companiesApp = createApp(Companies);
    companiesApp.mount('#companies-vue-app');
}
