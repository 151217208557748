export enum FilterIdCourse {
    Language,
    Interest,
    Type,
}

export enum FilterIdVacancy {
    Experience,
    Interest, 
    JobType,
    Company
}

export enum FilterIdCompany {
    Subject,
    Type
}