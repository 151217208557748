<template>
    <div class="grid-overview__container u-container">
        <h2 class="grid-overview__title title title--l" v-if="!companies.loading && customTitle != '' ">{{ customTitle }}</h2>
        <div class="grid-overview__filters">
            <div class="grid-overview__filter filter filter--search">
                <SearchFilter 
                    :searchQueryText="selectQuery.search"
                    :placeholder="searchText"
                    @input="setSearchQuery" 
                />
            </div>
            <div
                class="grid-overview__filter filter"
                v-for="(filter, index) in filters"
                :key="index"
            >
            <DropdownFilter
                :label="filter.label"
                :items="filter.options"
                :selectedOptions="filter.selected"
                @update:selectedOptions="(value: string[]) => setSelectedQuery(value, filter.id)"
            />
            </div>
        </div>
        <template v-if="companies.loading">
            <p>{{ resultsLoadingText }}...
            </p>
        </template>
        <template v-if="!companies.loading">
            <h3 class="grid-overview__title title title--m">{{ filteredCompaniesCount }} {{ resultTitle }}</h3>
            <ul class="grid-overview__selected-filters" v-if="selectedFilters.length > 0">
                <li v-for="(filter, index) in selectedFilters" :key="index" class="grid-overview__selected-filter">
                    {{ filter }}
                </li>
                <button class="grid-overview__button grid-overview__button--reset"
                    @click="clearAllFilters">
                    {{ resetText }}
                    <svg class="grid-overview__button-svg" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 22.75L11.75 31C11.3611 31.3889 10.9027 31.5833 10.375 31.5833C9.84718 31.5833 9.38885 31.3889 8.99996 31C8.61107 30.6111 8.41663 30.1528 8.41663 29.625C8.41663 29.0972 8.61107 28.6389 8.99996 28.25L17.25 20L8.99996 11.75C8.61107 11.3611 8.41663 10.9028 8.41663 10.375C8.41663 9.84723 8.61107 9.38889 8.99996 9.00001C9.38885 8.61112 9.84718 8.41667 10.375 8.41667C10.9027 8.41667 11.3611 8.61112 11.75 9.00001L20 17.25L28.25 9.00001C28.6388 8.61112 29.0972 8.41667 29.625 8.41667C30.1527 8.41667 30.6111 8.61112 31 9.00001C31.3888 9.38889 31.5833 9.84723 31.5833 10.375C31.5833 10.9028 31.3888 11.3611 31 11.75L22.75 20L31 28.25C31.3888 28.6389 31.5833 29.0972 31.5833 29.625C31.5833 30.1528 31.3888 30.6111 31 31C30.6111 31.3889 30.1527 31.5833 29.625 31.5833C29.0972 31.5833 28.6388 31.3889 28.25 31L20 22.75Z" fill="black"/>
                    </svg>
                </button>
            </ul>
            <TransitionGroup
                name="grid-overview__list"
                tag="ul"
                class="grid-overview__list"
                v-if="filteredCompanies.length > 0"
            >
                <CompanyItem 
                    v-for="(company, index) in filteredCompanies" 
                    :key = index
                    :company="company"
                    :languageCode="languageCode"
                    :vacancyText="vacancyText" 
                    :vacanciesText="vacanciesText" 
                />
            </TransitionGroup>
            <transition
                name="companies__no-items"
                mode="out-in"
                appear
                v-if="filteredCompanies.length === 0 && !companies.loading"
            >
                <p class="grid-overview__no-results">
                    {{ noResultsText }}
                </p>
            </transition>
        </template>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive, computed, watch, onMounted } from "vue";
import axios from "axios";
import CompanyItem from '../components/CompanyItem.vue';
import DropdownFilter from '../components/DropdownFilter.vue';
import SearchFilter from '../components/SearchFilter.vue';
import type { Company } from "../company.interface";
import type { FilterConfigurationCompany } from "../filter.interface";
import { FilterIdCompany } from "../filter.enum";

const companies = reactive({
    items: [] as Company[],
    limit: 50,
    page: 1,
    loading: false,
    allPostsLoaded: false
});

let filtersConfiguration = reactive<FilterConfigurationCompany[]>([]);

const params = new URLSearchParams(location.search);
const paramSubject = params.get("subject")?.split(',') || [];
const paramType = params.get("type")?.split(',') || [];
const paramSearch = params.get("search") || "";

// Make sure the url parameters are capitalized, since the filtering is case sensitive
let newParamSubject: string[] = [];
if (paramSubject) {
    newParamSubject = paramSubject.map(
        subject => `${subject.charAt(0).toUpperCase()}${subject.slice(1)}`
    );
}

let newParamType: string[] = [];
if (paramType) {
    newParamType = paramType.map(
        type => `${type.charAt(0).toUpperCase()}${type.slice(1)}`
    );
}

// Translation refs
const languageCode = ref('');
const customTitle = ref('');
const resultText = ref('');
const resultsText = ref('');
const resetText = ref('');
const resultsLoadingText = ref('');
const noResultsText = ref('');
const resultsMore = ref('');
const filterLabelSubject = ref('');
const filterLabelType = ref('');
const vacancyText = ref('');
const vacanciesText = ref('');
const searchText = ref('');
const el = document.getElementById('companies-vue-app');
  
// Single or multiple results text
const resultTitle = computed(() => {
    const companiesLength = filteredCompanies.value.length;
    return companiesLength === 1 ? resultText.value : resultsText.value;
});
const filteredCompaniesCount = computed(() => {
  return filteredCompanies.value.length;
});

// Search functionality
const searchQuery = reactive({
    text: '',
});

function setSearchQuery(x:string) {
    searchQuery.text = x;
    selectQuery.search = x;
};

// Load all companies initially
async function loadCompanies () { 
    companies.loading = true;
    try {
        const response = await axios.get(`/wp-json/wp/v2/company?lang=${languageCode.value}&page=${companies.page}&per_page=${companies.limit}&order=asc&orderby=title`);
        companies.items.push(...response.data.map((company: { title: { rendered: any; }; }) => ({...company, title: company.title.rendered})));
        
        // Check if the fetched items are equal to the limit, indicating there might be more items to fetch
        if (response.data.length === companies.limit) {
            companies.page += 1; // Increment the page
            await loadCompanies(); // Recursive call to loadCompanies
        } else {
            companies.allPostsLoaded = true; // Set allPostsLoaded to true when there are no more items
        }
    } catch (error) {
        console.error("Error loading companies:", error);
    } finally {
        companies.loading = false;
    }
}

// Filter companies
const filteredCompanies = computed(() => {
    const subjectFilter = selectQuery.subject;
    const typeFilter = selectQuery.type;
    const textFilter = selectQuery.search; // Add this line

    const filtered = companies.items.filter((company: Company) => {
        // Apply text search filter
        function decodeHTML({ html }: { html: any; }): string {
            var text = document.createElement('textarea');
            text.innerHTML = html;
            return text.value;
        }

        if (textFilter.length > 0 ) {  // Use textFilter instead of searchQuery.text
            const searchText = decodeHTML({ html: textFilter.toLowerCase() });
            const hasMatchingSubject = company.taxonomies.subject?.some(subject => typeof decodeHTML({ html: subject.name }) === 'string' && decodeHTML({ html: subject.name }).toLowerCase().includes(searchText));
            const hasMatchingType = company.taxonomies.type?.some(type => typeof decodeHTML({ html: type.name }) === 'string' && decodeHTML({ html: type.name }).toLowerCase().includes(searchText));
            
            if (!(typeof decodeHTML({ html: company.title }) === 'string' && decodeHTML({ html: company.title }).toLowerCase().includes(searchText)) 
            && !decodeHTML({ html: company.acf.location }).toLowerCase().includes(searchText)
            && !hasMatchingSubject 
            && !hasMatchingType) {
                return false;
            }
        }

        // Apply subject filter
        if (subjectFilter.length > 0 && (!company.taxonomies || !company.taxonomies.subject.some((subject: { name: string; }) => subjectFilter.includes(subject.name)))) {
            return false;
        }
        
        // Apply type filter
        if (typeFilter.length > 0 && (!company.taxonomies || !company.taxonomies.type.some((type: { name: string; }) => typeFilter.includes(type.name)))) {
            return false;
        }

        return true;
    });
    return filtered;
});


const selectedFilters = computed(() => {
    const filters = [];
    if (selectQuery.subject.length > 0) {
        filters.push(...selectQuery.subject);
    }
    if (selectQuery.type.length > 0) {
        filters.push(...selectQuery.type);
    }
    return filters;
});

const selectQuery = reactive({
    subject: paramSubject ? paramSubject : [],
    type: paramType ? paramType : [],
    search: paramSearch ? paramSearch : ""
});

function updateUrl() {
    const newurl = new URL(window.location.href);

    if (selectQuery.subject.length) {
        newurl.searchParams.set('subject', selectQuery.subject.join(','));
    } else {
        newurl.searchParams.delete('subject');
    }

    if (selectQuery.type.length) {
        newurl.searchParams.set('type', selectQuery.type.join(','));
    } else {
        newurl.searchParams.delete('type');
    }

    if (selectQuery.search.length) {
        newurl.searchParams.set("search", selectQuery.search); 
    } else {
        newurl.searchParams.delete("search");
    }

    window.history.pushState({path: newurl.href}, '', newurl.href);
}

function setSelectedQuery(values: string[], id: FilterIdCompany) {
    switch (id) {
        case FilterIdCompany.Subject:
            selectQuery.subject = values;
            break;
        case FilterIdCompany.Type:
            selectQuery.type = values;
            break;
    }

    const filterConfig = filtersConfiguration.find(filter => filter.id === id);
    if (filterConfig) {
        filterConfig.selected = values;
    }
    updateUrl();
}

// Reset filters
function clearAllFilters() {
    selectQuery.subject = [];
    selectQuery.type = [];

    filtersConfiguration.forEach(filterConfig => {
        filterConfig.selected = [];
    });
}

// Non-mutually exclusive filtered companies (AKA making sure a filter is not selectable when no results occur within that selection)
const nonMutuallyExclusiveFilteredCompanies = (filterIdToExclude: FilterIdCompany): Company[] => {
    const subjectFilter = filterIdToExclude !== FilterIdCompany.Subject ? selectQuery.subject : [];
    const typeFilter = filterIdToExclude !== FilterIdCompany.Type ? selectQuery.type : [];

    const filtered = companies.items.filter((company: Company) => {
        if (subjectFilter.length > 0 && (!company.taxonomies || !company.taxonomies.subject.some(subject => subjectFilter.includes(subject.name)))) {
            return false;
        }

        if (typeFilter.length > 0 && (!company.taxonomies || !company.taxonomies.type.some(type => typeFilter.includes(type.name)))) {
            return false;
        }

        return true;
    });
    return filtered;
};

// Filters configuration
const filters = computed(() => {
    return filtersConfiguration.map(filterConfig => {
        return {
            id: filterConfig.id,
            label: filterConfig.label,
            options: [...new Set([...filterConfig.optionsProvider(), ...filterConfig.selected])], // Merge current options with selected options
            selected: filterConfig.selected,
            isEmpty: filterConfig.optionsProvider().length === 0 && filterConfig.selected.length === 0 // Define an isEmpty property
        }
    })
});

watch(() => selectQuery, () => {
    filteredCompanies.value;
});

onMounted(() => {

    if (el) {
        // Set data attributes for wordpress translations
        languageCode.value = el.getAttribute('data-language') || '';
        customTitle.value = el.getAttribute('data-custom-title') || '';
        resultText.value = el.getAttribute('data-result-text') || '';
        resultsText.value = el.getAttribute('data-results-text') || '';
        resetText.value = el.getAttribute('data-reset') || '';
        resultsLoadingText.value = el.getAttribute('data-result-loading') || '';
        resultsMore.value = el.getAttribute('data-results-more') || '';
        noResultsText.value = el.getAttribute('data-no-results') || '';
        filterLabelSubject.value = el.getAttribute('data-filter-subject') || '';
        filterLabelType.value = el.getAttribute('data-filter-type') || '';
        vacancyText.value = el.getAttribute('data-vacancy-text') || '';
        vacanciesText.value = el.getAttribute('data-vacancies-text') || '';
        searchText.value = el.getAttribute('data-search-text') || '';

        // Set filter config (useful for reusing the filter component)
        filtersConfiguration.push(
            {
                id: FilterIdCompany.Subject,
                label: filterLabelSubject.value, 
                optionsProvider: () => {
                    const companies = nonMutuallyExclusiveFilteredCompanies(FilterIdCompany.Subject);
                    return Array.from(new Set(companies.flatMap((company: Company) => company.taxonomies?.subject?.map(l => l.name) ?? [])))
                },
                selected: newParamSubject
            },
            {
                id: FilterIdCompany.Type,
                label: filterLabelType.value, 
                optionsProvider: () => {
                    const companies = nonMutuallyExclusiveFilteredCompanies(FilterIdCompany.Type);
                    return Array.from(new Set(companies.flatMap((company: Company) => company.taxonomies?.type?.map(l => l.name) ?? [])))
                },
                selected: newParamType
            }
        )

    }

    loadCompanies()
    return filtersConfiguration;
})

</script>