<template>
    <div class="list-overview__container u-container">
        <h1 class="list-overview__title title title--l" v-if="customTitle != '' ">{{ customTitle }}</h1>
        <template v-if="courses.loading">
            <p>{{ resultsLoadingText }}...
            </p>
        </template>
        <template v-if="!courses.loading">
            <div class="list-overview__filters"
                v-if="courses.items.length > 2 && overview_items !== 'experiences'">
                <div
                    class="list-overview__filter filter"
                    v-for="(filter, index) in filters"
                    :key="index"
                >
                <DropdownFilter
                    :label="filter.label"
                    :items="filter.options"
                    :selectedOptions="filter.selected"
                    @update:selectedOptions="(value: string[]) => setSelectedQuery(value, filter.id)"
                />
                </div>
            </div>
            <template v-if="courses.loading">
                <p>{{ resultsLoadingText }}...
                </p>
            </template>
            <h2 class="list-overview__title title title--m" v-else-if="!courses.loading && filteredCoursesCount > 0 && overview_items !== 'experiences'">{{ filteredCoursesCount }} {{ resultTitle }}</h2>
            <ul class="list-overview__selected-filters" v-if="selectedFilters.length > 0">
                <li v-for="(filter, index) in selectedFilters" :key="index" class="list-overview__selected-filter">
                    {{ filter }}
                </li>
                <button class="list-overview__button list-overview__button--reset"
                    @click="clearAllFilters">
                    {{ resetText }}
                    <svg class="list-overview__button-svg" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 22.75L11.75 31C11.3611 31.3889 10.9027 31.5833 10.375 31.5833C9.84718 31.5833 9.38885 31.3889 8.99996 31C8.61107 30.6111 8.41663 30.1528 8.41663 29.625C8.41663 29.0972 8.61107 28.6389 8.99996 28.25L17.25 20L8.99996 11.75C8.61107 11.3611 8.41663 10.9028 8.41663 10.375C8.41663 9.84723 8.61107 9.38889 8.99996 9.00001C9.38885 8.61112 9.84718 8.41667 10.375 8.41667C10.9027 8.41667 11.3611 8.61112 11.75 9.00001L20 17.25L28.25 9.00001C28.6388 8.61112 29.0972 8.41667 29.625 8.41667C30.1527 8.41667 30.6111 8.61112 31 9.00001C31.3888 9.38889 31.5833 9.84723 31.5833 10.375C31.5833 10.9028 31.3888 11.3611 31 11.75L22.75 20L31 28.25C31.3888 28.6389 31.5833 29.0972 31.5833 29.625C31.5833 30.1528 31.3888 30.6111 31 31C30.6111 31.3889 30.1527 31.5833 29.625 31.5833C29.0972 31.5833 28.6388 31.3889 28.25 31L20 22.75Z" fill="black"/>
                    </svg>
                </button>
            </ul>
            <TransitionGroup
                name="list-overview__list"
                tag="ul"
                class="list-overview__list"
                v-if="filteredCourses.length > 0 && !courses.loading && filteredCoursesCount > 0"
            >
            <template v-for="(item, index) in visibleCourses">
                <CourseItem 
                    v-if="!item.isMediaContent"
                    :key="`course-${index}`"
                    :course="item" 
                />
                <MediaContentComponent 
                    v-else-if="asMediaContent(item).image && asMediaContent(item).title && asMediaContent(item).text"
                    :key="`media-${index}`"
                    :id="asMediaContent(item).id"
                    :title="asMediaContent(item).title"
                    :text="asMediaContent(item).text"
                    :image="asMediaContent(item).image"
                    :imageAlt="asMediaContent(item).imageAlt"
                    :label="asMediaContent(item).label"
                    :link="asMediaContent(item).link"
                    :color="asMediaContent(item).color"
                />
            </template>
                <div class="list-overview__cta cta"
                    v-if="ctaTitle != ''">
                    <div class="cta__container u-container cta__container--orange cta__container--">
                        <div class="cta__content">
                            <div class="cta__title title title--l">
                                {{ ctaTitle }}
                            </div>
                            <div class="cta__text text">
                                {{ ctaText }}
                            </div>
                            <div class="cta__button-container">
                                <a :href="ctaUrl" class="cta__button button button--arrow">
                                    {{ ctaLabel }}
                                </a>
                            </div>
                        </div>
                        <div class="cta__image-container" v-if="ctaImage">
                            <picture>
                                <source :srcset="ctaImage" type="image/webp">
                                <img :src="ctaImage" :alt="ctaImageAlt" class="cta__image">
                            </picture>
                        </div>
                    </div>
                </div>
            </TransitionGroup>
            <button class="list-overview__button button button--blue" @click="loadMore" v-if="visibleCourses.length < filteredCourses.length">
                {{ resultsMore }}
            </button>
            <transition
                name="courses__no-items"
                mode="out-in"
                appear
                v-if="filteredCourses.length === 2 && !courses.loading"
            >
                <p class="list-overview__no-results">
                    {{ noResultsText }}
                </p>
            </transition>
        </template>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive, computed, watch, onMounted } from "vue";
import axios from "axios";
import CourseItem from '../components/CourseItem.vue';
import MediaContentComponent from '../components/MediaContent.vue';
import DropdownFilter from '../components/DropdownFilter.vue';
import type { Course, MediaContent } from "../course.interface";
import type { FilterConfigurationCourse } from "../filter.interface";
import { FilterIdCourse } from "../filter.enum";

type CourseOrMedia = Course | MediaContent;

const courses = reactive({
    items: [] as CourseOrMedia[],
    limit: 100,
    page: 1,
    loading: false,
    allPostsLoaded: false
});

const maxVisibleItems = ref(10);

let filtersConfiguration = reactive<FilterConfigurationCourse[]>([]);

const params = new URLSearchParams(location.search);
const paramInterest = params.get("course_interest")?.split(',') || [];
const paramType = params.get("course_type")?.split(',') || [];
const paramLanguage = params.get("language")?.split(',') || [];

// Make sure the url parameters are capitalized, since the filtering is case sensitive
let newParamInterest: string[] = [];
if (paramInterest) {
    newParamInterest = paramInterest.map(
        interest => `${interest.charAt(0).toUpperCase()}${interest.slice(1)}`
    );
}

let newParamType: string[] = [];
if (paramType) {
    newParamType = paramType.map(
        type => `${type.charAt(0).toUpperCase()}${type.slice(1)}`
    );
}

let newParamLanguage: string[] = [];
if (paramLanguage) {
    newParamLanguage = paramLanguage.map(
        language => `${language.charAt(0).toUpperCase()}${language.slice(1)}`
    );
}

// Translation refs
const overview_items = ref('');
const languageCode = ref('');
const customTitle = ref('');
const resultText = ref('');
const resultsText = ref('');
const resetText = ref('');
const resultsLoadingText = ref('');
const noResultsText = ref('');
const resultsMore = ref('');
const filterLabelInterest = ref('');
const filterLabelType = ref('');
const filterLabelLanguage = ref('');
const ctaTitle = ref('');
const ctaText = ref('');
const ctaImage = ref('');
const ctaImageAlt = ref('');
const ctaLabel = ref('');
const ctaUrl = ref('');
const mediaContentTitle1 = ref('');
const mediaContentText1 = ref('');
const mediaContentImage1 = ref('');
const mediaContentImageAlt1 = ref('');
const mediaContentLabel1 = ref('');
const mediaContentUrl1 = ref('');
const mediaContentTitle2 = ref('');
const mediaContentText2 = ref('');
const mediaContentImage2 = ref('');
const mediaContentImageAlt2 = ref('');
const mediaContentLabel2 = ref('');
const mediaContentUrl2 = ref('');
const el = document.getElementById('courses-vue-app');
  
// Single or multiple results text
const resultTitle = computed(() => {
    const coursesLength = filteredCoursesCount.value;
    return coursesLength === 1 ? resultText.value : resultsText.value;
});
const filteredCoursesCount = computed(() => {
  return filteredCourses.value.filter(item => !item.isMediaContent).length;
});

async function loadCourses () { 
    courses.loading = true;
    try {
        
        let response;
        let items = await axios.get(`/wp-json/wp/v2/course?lang=${languageCode.value}&page=${courses.page}&per_page=${courses.limit}&order=asc&orderby=title`);
        let newItems;

        if (overview_items.value === 'experiences') {
            // Filter the response for taxonomy 'course_type' where the name is 'Experience Campus'
            response = items.data.filter((item: { taxonomies: { course_type: any[]; }; }) => {
                return (
                    item.taxonomies &&
                    item.taxonomies.course_type &&
                    item.taxonomies.course_type.some(type => type.name === 'Experience Campus')
                );
            });

            newItems = response.map((item: any) => ({
                ...item,
                isMediaContent: false,
            }));
        } else {
            response = items;

            newItems = response.data.map((item: any) => ({
                ...item,
                isMediaContent: false,
            }));
        }

        courses.items.push(...newItems);

        if (newItems.length === courses.limit) {
            courses.page += 1; // Increment the page
            await loadCourses(); // Recursive call to loadCourses
        } else {

            // Create MediaContentComponent data
            const mediaContent1 = {
                id: 0,
                isMediaContent: true,
                title: mediaContentTitle1.value,
                text: mediaContentText1.value,
                image: mediaContentImage1.value,
                imageAlt: mediaContentImageAlt1.value,
                label: mediaContentLabel1.value,
                link: mediaContentUrl1.value,
                color: 'orange'
            };

            const mediaContent2 = {
                id: 1,
                isMediaContent: true,
                title: mediaContentTitle2.value,
                text: mediaContentText2.value,
                image: mediaContentImage2.value,
                imageAlt: mediaContentImageAlt2.value,
                label: mediaContentLabel2.value,
                link: mediaContentUrl2.value,
                color: 'green'
            };

            // Insert MediaContentComponents into specific positions
            courses.items.splice(4, 0, mediaContent1);  // Between fourth and fifth item
            courses.items.splice(8, 0, mediaContent2);  // Between eighth and ninth item

            courses.allPostsLoaded = true;
        }

        courses.loading = false;
    } catch (error) {
        console.error("Error loading courses:", error);
    } finally {
        courses.loading = false;
    }
}

// Filter courses
const filteredCourses = computed(() => {
    const interestFilter = selectQuery.interest;
    const typeFilter = selectQuery.type;
    const languageFilter = selectQuery.language;

    // const filtered = courses.items.filter((course: Course) => {
    const filtered = courses.items.filter((item: CourseOrMedia) => {
        if (item.isMediaContent) {
            return true;
        }

        const course = item as Course;

        if (interestFilter.length > 0 && (!course.taxonomies || !course.taxonomies.course_interest.some(course_interest => interestFilter.includes(course_interest.name)))) {
            return false;
        }

        if (typeFilter.length > 0 && (!course.taxonomies || !course.taxonomies.course_type.some(course_type => typeFilter.includes(course_type.name)))) {
            return false;
        }

        if (languageFilter.length > 0 && (!course.taxonomies || !course.taxonomies.language.some(language => languageFilter.includes(language.name)))) {
            return false;
        }

        return true;
    });
    return filtered;
});

const selectedFilters = computed(() => {
    const filters = [];
    if (selectQuery.interest.length > 0) {
        filters.push(...selectQuery.interest);
    }
    if (selectQuery.type.length > 0) {
        filters.push(...selectQuery.type);
    }
    if (selectQuery.language.length > 0) {
        filters.push(...selectQuery.language);
    }
    return filters;
});

const selectQuery = reactive({
    interest: paramInterest ? paramInterest : [],
    type: paramType ? paramType : [],
    language: paramLanguage ? paramLanguage : [],
});

function updateUrl() {
    const newurl = new URL(window.location.href);

    if (selectQuery.interest.length) {
        newurl.searchParams.set('course_interest', selectQuery.interest.join(','));
    } else {
        newurl.searchParams.delete('course_interest');
    }

    if (selectQuery.type.length) {
        newurl.searchParams.set('course_type', selectQuery.type.join(','));
    } else {
        newurl.searchParams.delete('course_type');
    }

    if (selectQuery.language.length) {
        newurl.searchParams.set('language', selectQuery.language.join(','));
    } else {
        newurl.searchParams.delete('language');
    }

    window.history.pushState({path: newurl.href}, '', newurl.href);
}

function setSelectedQuery(values: string[], id: FilterIdCourse) {
    switch (id) {
        case FilterIdCourse.Language:
            selectQuery.language = values;
            break;
        case FilterIdCourse.Interest:
            selectQuery.interest = values;
            break;
        case FilterIdCourse.Type:
            selectQuery.type = values;
            break;
    }

    const filterConfig = filtersConfiguration.find(filter => filter.id === id);
    if (filterConfig) {
        filterConfig.selected = values;
    }
    updateUrl();
}

// Reset filters
function clearAllFilters() {
    selectQuery.interest = [];
    selectQuery.type = [];
    selectQuery.language = [];

    filtersConfiguration.forEach(filterConfig => {
        filterConfig.selected = [];
    });
}

// Non-mutually exclusive filtered courses (AKA making sure a filter is not selectable when no results occur within that selection)
const nonMutuallyExclusiveFilteredCourses = (FilterIdCourseToExclude: FilterIdCourse): Course[] => {
    const interestFilter = FilterIdCourseToExclude !== FilterIdCourse.Interest ? selectQuery.interest : [];
    const typeFilter = FilterIdCourseToExclude !== FilterIdCourse.Type ? selectQuery.type : [];
    const languageFilter = FilterIdCourseToExclude !== FilterIdCourse.Language ? selectQuery.language : [];

    const filtered = courses.items
        .filter((item: CourseOrMedia): item is Course => !item.isMediaContent)  // Only keep Course items
        .filter((course: Course) => {
            if (interestFilter.length > 0 && (!course.taxonomies || !course.taxonomies.course_interest.some(course_interest => interestFilter.includes(course_interest.name)))) {
                return false;
            }

            if (typeFilter.length > 0 && (!course.taxonomies || !course.taxonomies.course_type.some(course_type => typeFilter.includes(course_type.name)))) {
                return false;
            }

            if (languageFilter.length > 0 && (!course.taxonomies || !course.taxonomies.language.some(language => languageFilter.includes(language.name)))) {
                return false;
            }

            return true;
        });
    return filtered;
};

// Filters configuration
const filters = computed(() => {
    return filtersConfiguration.map(filterConfig => {
        return {
            id: filterConfig.id,
            label: filterConfig.label,
            options: [...new Set([...filterConfig.optionsProvider(), ...filterConfig.selected])], // Merge current options with selected options
            selected: filterConfig.selected,
            isEmpty: filterConfig.optionsProvider().length === 0 && filterConfig.selected.length === 0 // Define an isEmpty property
        }
    })
});

// Only show certain amount of items
const visibleCourses = computed(() => {
    return filteredCourses.value.slice(0, maxVisibleItems.value) as CourseOrMedia[];
});

// 'Load' more courses
function loadMore() {
    maxVisibleItems.value += 2;
}

function asMediaContent(item: CourseOrMedia): MediaContent {
    return item as MediaContent;
}

function isMediaContentEmpty(mediaContent: MediaContent) {
    return !mediaContent.title && !mediaContent.text && !mediaContent.image && !mediaContent.label && !mediaContent.link;
}

watch(() => selectQuery, () => {
    filteredCourses.value;
});

onMounted(() => {

    if (el) {
        // Set data attributes for wordpress translations
        overview_items.value = el.getAttribute('data-overview-items') || '';
        languageCode.value = el.getAttribute('data-language') || '';
        customTitle.value = el.getAttribute('data-custom-title') || '';
        resultText.value = el.getAttribute('data-result-text') || '';
        resultsText.value = el.getAttribute('data-results-text') || '';
        resetText.value = el.getAttribute('data-reset') || '';
        resultsLoadingText.value = el.getAttribute('data-result-loading') || '';
        resultsMore.value = el.getAttribute('data-results-more') || '';
        noResultsText.value = el.getAttribute('data-no-results') || '';
        filterLabelInterest.value = el.getAttribute('data-filter-interest') || '';
        filterLabelType.value = el.getAttribute('data-filter-type') || '';
        filterLabelLanguage.value = el.getAttribute('data-filter-language') || '';
        ctaTitle.value = el.getAttribute('data-cta-title') || '';
        ctaText.value = el.getAttribute('data-cta-text') || '';
        ctaImage.value = el.getAttribute('data-cta-image') || '';
        ctaImageAlt.value = el.getAttribute('data-cta-image-alt') || '';
        ctaLabel.value = el.getAttribute('data-cta-label') || '';
        ctaUrl.value = el.getAttribute('data-cta-url') || '';
        mediaContentTitle1.value = el.getAttribute('data-media-content-title-1') || '';
        mediaContentText1.value = el.getAttribute('data-media-content-text-1') || '';
        mediaContentImage1.value = el.getAttribute('data-media-content-image-1') || '';
        mediaContentImageAlt1.value = el.getAttribute('data-media-content-image-alt-1') || '';
        mediaContentLabel1.value = el.getAttribute('data-media-content-label-1') || '';
        mediaContentUrl1.value = el.getAttribute('data-media-content-url-1') || '';
        mediaContentTitle2.value = el.getAttribute('data-media-content-title-2') || '';
        mediaContentText2.value = el.getAttribute('data-media-content-text-2') || '';
        mediaContentImage2.value = el.getAttribute('data-media-content-image-2') || '';
        mediaContentImageAlt2.value = el.getAttribute('data-media-content-image-alt-2') || '';
        mediaContentLabel2.value = el.getAttribute('data-media-content-label-2') || '';
        mediaContentUrl2.value = el.getAttribute('data-media-content-url-2') || '';

        // Set filter config (useful for reusing the filter component)
        filtersConfiguration.push(
            {
                id: FilterIdCourse.Interest,
                label: filterLabelInterest.value, 
                optionsProvider: () => {
                    const courses = nonMutuallyExclusiveFilteredCourses(FilterIdCourse.Interest);
                    return Array.from(new Set(courses.flatMap((course: Course) => course.taxonomies?.course_interest?.map(l => l.name) ?? [])))
                },
                selected: newParamInterest
            },
            {
                id: FilterIdCourse.Type,
                label: filterLabelType.value, 
                optionsProvider: () => {
                    const courses = nonMutuallyExclusiveFilteredCourses(FilterIdCourse.Type);
                    return Array.from(new Set(courses.flatMap((course: Course) => course.taxonomies?.course_type?.map(l => l.name) ?? [])))
                },
                selected: newParamType
            },
            {
                id: FilterIdCourse.Language,
                label: filterLabelLanguage.value, 
                optionsProvider: () => {
                    const courses = nonMutuallyExclusiveFilteredCourses(FilterIdCourse.Language);
                    return Array.from(new Set(courses.flatMap((course: Course) => course.taxonomies?.language?.map(l => l.name) ?? [])))
                },
                selected: newParamLanguage
            }
        )

    }

    loadCourses()
    return filtersConfiguration;
})

</script>