<template>
    <li class="companies-overview__item small-card small-card--company">
        <a :href="props.company.link" class="small-card__url">
            <div class="small-card__image-container">
                <picture v-if="props.company.featured_media !== 0 ">
                    <img class="small-card__image"  :src="props.company.featured_img.url" :alt="props.company.featured_img.alt">
                </picture>
            </div>
            <div class="small-card__content">
                <div class="small-card__title title" v-html="props.company.title">
                </div>
                <div class="small-card__location text" v-if="props.company.acf.location" v-html="props.company.acf.location"></div>
                <ul class="small-card__tags" v-if="taxonomies.length > 0">
                    <li class="small-card__tag" v-for="(taxonomy, index) in taxonomies">{{ taxonomy }}</li>
                </ul>
                <div class="small-card__vacancies" v-if="props.company.vacancies_count > 0">
                    {{ props.company.vacancies_count }} {{ resultVacancies }}
                </div>
            </div>
        </a>
    </li>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
    company: { 
        type: Object, 
        required: true 
    },
    languageCode: {
        type: String,
        required: true,
    },
    vacancyText: {
        type: String,
        required: true
    },
    vacanciesText: {
        type: String,
        required: true
    }
})

const taxonomies = computed(() => { 
    const receivedTaxonomies: any[] = [];

    if (props.company.taxonomies.type.length > 0) {
        props.company.taxonomies.type.forEach((type: { name: any; }) => {
            receivedTaxonomies.push(type.name);
        })
    }
    if (props.company.taxonomies.subject.length > 0) {
        props.company.taxonomies.subject.forEach((subject: { name: any; }) => {
            receivedTaxonomies.push(subject.name);
        })
    }
    return receivedTaxonomies;
})

// Single or multiple results text
const resultVacancies = computed(() => {
    const vacanciesCount = props.company.vacancies_count;
    return vacanciesCount === 1 ? props.vacancyText : props.vacanciesText;
});

</script>