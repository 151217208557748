<template>
    <button class="filter__button" @click="toggleDropdown" ref="dropdownElement">
        <span class="filter__label">{{ label }}</span>
        <svg class="filter__dropdown-svg" :class="{ 'rotate': isOpen }" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5001 24.9167C20.2779 24.9167 20.0696 24.8817 19.8751 24.8117C19.6807 24.7428 19.5001 24.625 19.3335 24.4584L11.6251 16.75C11.3196 16.4445 11.174 16.0623 11.1885 15.6034C11.2018 15.1456 11.3613 14.7639 11.6668 14.4584C11.9724 14.1528 12.3613 14 12.8335 14C13.3057 14 13.6946 14.1528 14.0001 14.4584L20.5001 20.9584L27.0418 14.4167C27.3474 14.1112 27.7296 13.965 28.1885 13.9784C28.6463 13.9928 29.0279 14.1528 29.3335 14.4584C29.639 14.7639 29.7918 15.1528 29.7918 15.625C29.7918 16.0973 29.639 16.4862 29.3335 16.7917L21.6668 24.4584C21.5001 24.625 21.3196 24.7428 21.1251 24.8117C20.9307 24.8817 20.7224 24.9167 20.5001 24.9167Z" fill="#242D42"/>
        </svg>
    </button>
  
    <transition name="slider">
        <div class="filter__options" :class="{ 'open': isOpen }">
            <button
                class="filter__option"
                v-for="(item, index) in items"
                :key="index"
                :class="{'filter__option--is-selected': selectedOptions.includes(item)}"
                @click="event => toggleItem(item, event)"
                v-html="item"
            >
            </button>
        </div>
    </transition>

</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watchEffect, toRefs } from "vue";

const props = defineProps<{
    label: string;
    items: string[];
    selectedOptions: string[];
}>();

const emit = defineEmits(["update:selectedOptions"]);
const { label, items, selectedOptions } = toRefs(props);

let isOpen = ref(false);
let dropdownElement = ref<HTMLElement | null>(null);

const toggleDropdown = () => {
    isOpen.value = !isOpen.value;
};

const handleClickOutside = (event: MouseEvent) => {
    if (dropdownElement.value && !dropdownElement.value.contains(event.target as Node)) {
        isOpen.value = false;
    }
};

onMounted(() => {
    window.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
    window.removeEventListener('click', handleClickOutside);
});

const toggleItem = (item: string, event: MouseEvent) => {
    // stops closing the window after selecting a filter option
    event.stopPropagation(); 
    const index = selectedOptions.value.indexOf(item);
    if (index >= 0) {
        // Create a new array with the selected item removed
        selectedOptions.value = selectedOptions.value.filter((_, i) => i !== index);
    } else {
        // Create a new array with the selected item appended
        selectedOptions.value = [...selectedOptions.value, item];
    }
    emit("update:selectedOptions", selectedOptions.value);
};

watchEffect(() => {
    emit("update:selectedOptions", selectedOptions.value);
});

</script>
