<template>
    <li class="vacancies-overview__item card card--vacancy">
        <a :href="props.vacancy.link" class="card__url">
            <div class="card__content">
                <div class="card__details">
                    <div class="card__tag card__tag--" :style="{ backgroundColor: props.vacancy.taxonomies.interest[0].background_color,color: props.vacancy.taxonomies.interest[0].background_color === '#43547D' ? '#FFFFFF' : ''  }" v-html="props.vacancy.taxonomies.interest[0].name">
                    </div>
                    <div v-if="props.vacancy.acf.end && props.vacancy.acf.end != '' && props.vacancy.acf.format == 'date-time' || props.vacancy.acf.end_no_time && props.vacancy.acf.end_no_time != '' && props.vacancy.acf.format == 'date-only' " class="card__date">
                        {{ applyText }}{{ date }}
                    </div>
                </div>
                <div class="card__text-container">
                    <div class="card__title title" v-html="props.vacancy.title.rendered">
                    </div>
                    <div class="card__excerpt text" v-html="props.vacancy.excerpt.rendered"></div>
                </div>
                <div class="card__meta-data" v-if="props.vacancy.acf.time != '' || props.vacancy.acf.company != ''">
                    <div class="card__time" v-if="props.vacancy.acf.time != ''">
                        {{ props.vacancy.acf.time }}
                    </div>
                    <div class="card__location" v-if="props.vacancy.acf.company != '' ">
                        {{ props.vacancy.acf.company[0] }}
                    </div>
                </div>
                
            </div>
            <div class="card__button">
                <svg class="card__svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.975 12.9559C9.825 12.8059 9.75313 12.6246 9.75938 12.4121C9.76563 12.1996 9.84375 12.0184 9.99375 11.8684L12.1125 9.74961H3.75C3.5375 9.74961 3.35938 9.67773 3.21563 9.53398C3.07188 9.39023 3 9.21211 3 8.99961C3 8.78711 3.07188 8.60898 3.21563 8.46523C3.35938 8.32148 3.5375 8.24961 3.75 8.24961H12.1125L9.975 6.11211C9.825 5.96211 9.75 5.78398 9.75 5.57773C9.75 5.37148 9.825 5.19336 9.975 5.04336C10.125 4.89336 10.3031 4.81836 10.5094 4.81836C10.7156 4.81836 10.8938 4.89336 11.0438 5.04336L14.475 8.47461C14.55 8.54961 14.6031 8.63086 14.6344 8.71836C14.6656 8.80586 14.6813 8.89961 14.6813 8.99961C14.6813 9.09961 14.6656 9.19336 14.6344 9.28086C14.6031 9.36836 14.55 9.44961 14.475 9.52461L11.025 12.9746C10.8875 13.1121 10.7156 13.1809 10.5094 13.1809C10.3031 13.1809 10.125 13.1059 9.975 12.9559Z" fill="#242D42"/>
                </svg>
            </div>
        </a>
    </li>
</template>

<script setup lang="ts">
import { computed } from 'vue';


const props = defineProps({
    vacancy: { 
        type: Object, 
        required: true 
    },
    languageCode: {
        type: String,
        required: true,
    },
    applyText: {
        type: String,
        required: true
    }
})

const date = computed(() => {

if (props.vacancy.acf.end && props.vacancy.acf.format == "date-time" || props.vacancy.acf.end_no_time && props.vacancy.acf.format == "date-only" ) {
    function parseDateString(dateString: string): Date {
        const [day, month, yearTime] = dateString.split('-');
        const [year, time] = yearTime.split(' ');
        const [hours, minutes] = time.split(':');

        // Note: JavaScript's Date constructor expects the month to be 0-indexed, so we subtract 1 from the month number
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes));
    }

    const dateSanitized = props.vacancy.acf.end.replace(/\//g, '-');
    const dateObj = parseDateString(dateSanitized);
    let locale;
    if (props.languageCode === 'en') {
        locale = 'en-US';
    } else if (props.languageCode === 'nl') {
        locale = 'nl-NL';
    }
    return new Intl.DateTimeFormat(locale, { day: '2-digit', month: 'long', year: 'numeric'}).format(dateObj);
}
});


</script>