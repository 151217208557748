<template>
    <div class="list-overview__container u-container">
        <h1 class="list-overview__title title title--m" v-if="customTitle != '' ">{{ customTitle }}</h1>
        <template v-if="vacancies.loading">
            <p>{{ resultsLoadingText }}...
            </p>
        </template>
        <template v-if="!vacancies.loading">
            <p v-if="filteredVacanciesCount > 0" class="list-overview__job-oppertunities">{{ filteredVacanciesCount }} {{ jobOppertunitiesText }}</p>

            <div class="list-overview__filters"
                v-if="vacancies.items.length > 2"
            >
                <div
                    class="list-overview__filter filter"
                    v-for="(filter, index) in filters"
                    :key="index"
                >
                <DropdownFilter
                    :label="filter.label"
                    :items="filter.options"
                    :selectedOptions="filter.selected"
                    @update:selectedOptions="(value: string[]) => setSelectedQuery(value, filter.id)"
                />
                </div>
            </div>
            <template v-if="vacancies.loading">
                <p>{{ resultsLoadingText }}...
                </p>
            </template>
            <ul class="list-overview__selected-filters" v-if="selectedFilters.length > 0">
                <li v-for="(filter, index) in selectedFilters" :key="index" class="list-overview__selected-filter" v-html="filter">
                </li>
                <button class="list-overview__button list-overview__button--reset"
                    @click="clearAllFilters">
                    {{ resetText }}
                    <svg class="list-overview__button-svg" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 22.75L11.75 31C11.3611 31.3889 10.9027 31.5833 10.375 31.5833C9.84718 31.5833 9.38885 31.3889 8.99996 31C8.61107 30.6111 8.41663 30.1528 8.41663 29.625C8.41663 29.0972 8.61107 28.6389 8.99996 28.25L17.25 20L8.99996 11.75C8.61107 11.3611 8.41663 10.9028 8.41663 10.375C8.41663 9.84723 8.61107 9.38889 8.99996 9.00001C9.38885 8.61112 9.84718 8.41667 10.375 8.41667C10.9027 8.41667 11.3611 8.61112 11.75 9.00001L20 17.25L28.25 9.00001C28.6388 8.61112 29.0972 8.41667 29.625 8.41667C30.1527 8.41667 30.6111 8.61112 31 9.00001C31.3888 9.38889 31.5833 9.84723 31.5833 10.375C31.5833 10.9028 31.3888 11.3611 31 11.75L22.75 20L31 28.25C31.3888 28.6389 31.5833 29.0972 31.5833 29.625C31.5833 30.1528 31.3888 30.6111 31 31C30.6111 31.3889 30.1527 31.5833 29.625 31.5833C29.0972 31.5833 28.6388 31.3889 28.25 31L20 22.75Z" fill="black"/>
                    </svg>
                </button>
            </ul>
            <TransitionGroup
                name="list-overview__list"
                tag="ul"
                class="list-overview__list"
                v-if="filteredVacancies.length > 0 && !vacancies.loading && vacancies.items.length > 0"
            >
                <template v-for="(item, index) in visibleVacancies">
                    <VacancyItem 
                        v-if="!item.isMediaContent"
                        :key="`vacancy-${index}`"
                        :vacancy="item"
                        :languageCode="languageCode"
                        :applyText="applyText"
                    />
                    <MediaContentComponent 
                        v-else-if="asMediaContent(item).image && asMediaContent(item).title && asMediaContent(item).text"
                        :key="`media-${index}`"
                        :id="asMediaContent(item).id"
                        :title="asMediaContent(item).title"
                        :text="asMediaContent(item).text"
                        :image="asMediaContent(item).image"
                        :imageAlt="asMediaContent(item).imageAlt"
                        :label="asMediaContent(item).label"
                        :link="asMediaContent(item).link"
                        :color="asMediaContent(item).color"
                    />
                </template>
                <div class="list-overview__cta cta"
                    v-if="ctaTitle != ''">
                    <div class="cta__container u-container cta__container--orange cta__container--">
                        <div class="cta__content">
                            <div class="cta__title title title--l">
                                {{ ctaTitle }}
                            </div>
                            <div class="cta__text text">
                                {{ ctaText }}
                            </div>
                            <div class="cta__button-container">
                                <a :href="ctaUrl" class="cta__button button button--arrow">
                                    {{ ctaLabel }}
                                </a>
                            </div>
                        </div>
                        <div class="cta__image-container" v-if="ctaImage">
                            <picture>
                                <source :srcset="ctaImage" type="image/webp">
                                <img :src="ctaImage" :alt="ctaImageAlt" class="cta__image">
                            </picture>
                        </div>
                    </div>
                </div>
            </TransitionGroup>
            <button class="list-overview__button button button--blue" @click="loadMore" v-if="visibleVacancies.length < filteredVacancies.length">
                {{ resultsMore }}
            </button>
            <transition
                name="vacancies__no-items"
                mode="out-in"
                appear
                v-if="visibleVacancies.length === 2 && !vacancies.loading"
            >
                <p class="list-overview__no-results">
                    {{ noResultsText }}
                </p>
            </transition>
        </template>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive, computed, watch, onMounted } from "vue";
import axios from "axios";
import VacancyItem from '../components/VacancyItem.vue';
import MediaContentComponent from '../components/MediaContent.vue';
import DropdownFilter from '../components/DropdownFilter.vue';
import type { Vacancy, MediaContent } from "../vacancy.interface";
import type { FilterConfigurationVacancy } from "../filter.interface";
import { FilterIdVacancy } from "../filter.enum";

type VacancyOrMedia = Vacancy | MediaContent;

const vacancies = reactive({
    items: [] as VacancyOrMedia[],
    limit: 100,
    page: 1,
    loading: false,
    allPostsLoaded: false
});

const maxVisibleItems = ref(10);

let filtersConfiguration = reactive<FilterConfigurationVacancy[]>([]);

const params = new URLSearchParams(location.search);
const paramExperience = params.get("experience")?.split(',') || [];
const paramInterest = params.get("interest")?.split(',') || [];
const paramJobType = params.get("jobtype")?.split(',') || [];
const paramCompany = params.get("company_filter")?.split(',') || [];

// Make sure the url parameters are capitalized, since the filtering is case sensitive

let newParamExperience: string[] = [];
if (paramExperience) {
    newParamExperience = paramExperience.map(
        experience => `${experience.charAt(0).toUpperCase()}${experience.slice(1)}`
    );
}

let newParamInterest: string[] = [];
if (paramInterest) {
    newParamInterest = paramInterest.map(
        interest => `${interest.charAt(0).toUpperCase()}${interest.slice(1)}`
    );
}

let newParamJobType: string[] = [];
if (paramJobType) {
    newParamJobType = paramJobType.map(
        jobtype => `${jobtype.charAt(0).toUpperCase()}${jobtype.slice(1)}`
    );
}

let newParamCompany: string[] = [];
if (paramCompany) {
    newParamCompany = paramCompany.map(
        company_filter => `${company_filter.charAt(0).toUpperCase()}${company_filter.slice(1)}`
    );
}

// Translation refs
const languageCode = ref('');
const customTitle = ref('');
const jobOppertunities = ref('');
const jobOppertunity = ref('');
const applyText = ref('');
const resetText = ref('');
const resultsLoadingText = ref('');
const noResultsText = ref('');
const resultsMore = ref('');
const filterLabelInterest = ref('');
const filterLabelType = ref('');
const filterLabelExperience = ref('');
const filterLabelCompany = ref('');
const ctaTitle = ref('');
const ctaText = ref('');
const ctaImage = ref('');
const ctaImageAlt = ref('');
const ctaLabel = ref('');
const ctaUrl = ref('');
const mediaContentTitle1 = ref('');
const mediaContentText1 = ref('');
const mediaContentImage1 = ref('');
const mediaContentImageAlt1 = ref('');
const mediaContentLabel1 = ref('');
const mediaContentUrl1 = ref('');
const mediaContentTitle2 = ref('');
const mediaContentText2 = ref('');
const mediaContentImage2 = ref('');
const mediaContentImageAlt2 = ref('');
const mediaContentLabel2 = ref('');
const mediaContentUrl2 = ref('');
const el = document.getElementById('vacancies-vue-app');
  
// Single or multiple results text
const jobOppertunitiesText = computed(() => {
    const vacanciesLength = filteredVacanciesCount.value;
    return vacanciesLength === 1 ? jobOppertunity.value : jobOppertunities.value;
});
const filteredVacanciesCount = computed(() => {
  return filteredVacancies.value.filter(item => !item.isMediaContent).length;
});

async function loadVacancies () { 
    vacancies.loading = true;
    try {
        let totalPosts = 0;
        do {
            const response = await axios.get(`/wp-json/wp/v2/vacancy?lang=${languageCode.value}&page=${vacancies.page}&per_page=${vacancies.limit}&order=asc&orderby=title`);

            vacancies.items.push(...response.data.map((item: any) => ({
                ...item,
                isMediaContent: false,
            })));

        totalPosts = parseInt(response.headers['x-wp-total'], 10);
        
        vacancies.page += 1;
        } while (vacancies.items.length < totalPosts);

        // Create MediaContentComponent data
        const mediaContent1 = {
            id: 0,
            isMediaContent: true,
            title: mediaContentTitle1.value,
            text: mediaContentText1.value,
            image: mediaContentImage1.value,
            imageAlt: mediaContentImageAlt1.value,
            label: mediaContentLabel1.value,
            link: mediaContentUrl1.value,
            color: "green"
        };

        const mediaContent2 = {
            id: 1,
            isMediaContent: true,
            title: mediaContentTitle2.value,
            text: mediaContentText2.value,
            image: mediaContentImage2.value,
            imageAlt: mediaContentImageAlt2.value,
            label: mediaContentLabel2.value,
            link: mediaContentUrl2.value,
            color: "orange"
        };

        // Insert MediaContentComponents into specific positions
        vacancies.items.splice(4, 0, mediaContent1);  // Between fourth and fifth item
        vacancies.items.splice(7, 0, mediaContent2);  // Between seventh and eighth item

        vacancies.loading = false;
        vacancies.allPostsLoaded = true;
    } catch (error) {
        console.error("Error loading vacancies:", error);
        vacancies.loading = false;
    }
}

// Filter vacancies
const filteredVacancies = computed(() => {
    const experienceFilter = selectQuery.experience;
    const interestFilter = selectQuery.interest;
    const jobTypeFilter = selectQuery.jobtype;
    const companyFilter = selectQuery.company_filter;

    const filtered = vacancies.items.filter((item: VacancyOrMedia) => {
        if (item.isMediaContent) {
            return true;
        }

        const vacancy = item as Vacancy;

        if (experienceFilter.length > 0 && (!vacancy.taxonomies || !vacancy.taxonomies.experience.some(experience => experienceFilter.includes(experience.name)))) {
            return false;
        }

        if (interestFilter.length > 0 && (!vacancy.taxonomies || !vacancy.taxonomies.interest.some(interest => interestFilter.includes(interest.name)))) {
            return false;
        }

        if (jobTypeFilter.length > 0 && (!vacancy.taxonomies || !vacancy.taxonomies.jobtype.some(jobtype => jobTypeFilter.includes(jobtype.name)))) {
            return false;
        }

        if (companyFilter.length > 0 && (!vacancy.taxonomies || !Array.isArray(vacancy.acf.company) || !vacancy.acf.company.some(company => companyFilter.includes(company)))) {
            return false;
        }

        return true;
    });
    return filtered;
});

const selectedFilters = computed(() => {
    const filters = [];
    if (selectQuery.experience.length > 0) {
        filters.push(...selectQuery.experience);
    }
    if (selectQuery.interest.length > 0) {
        filters.push(...selectQuery.interest);
    }
    if (selectQuery.jobtype.length > 0) {
        filters.push(...selectQuery.jobtype);
    }
    if (selectQuery.company_filter.length > 0) {
        filters.push(...selectQuery.company_filter);
    }
    return filters;
});

const selectQuery = reactive({
    experience: paramExperience ? paramExperience : [],
    interest: paramInterest ? paramInterest : [],
    jobtype: paramJobType ? paramJobType : [],
    company_filter: paramCompany ? paramCompany : [],
});

function updateUrl() {
    const newurl = new URL(window.location.href);

    if (selectQuery.experience.length) {
        newurl.searchParams.set('experience', selectQuery.experience.join(','));
    } else {
        newurl.searchParams.delete('experience');
    }

    if (selectQuery.interest.length) {
        newurl.searchParams.set('interest', selectQuery.interest.join(','));
    } else {
        newurl.searchParams.delete('interest');
    }

    if (selectQuery.jobtype.length) {
        newurl.searchParams.set('jobtype', selectQuery.jobtype.join(','));
    } else {
        newurl.searchParams.delete('jobtype');
    }
    
    if (selectQuery.company_filter.length) {
        newurl.searchParams.set('company_filter', selectQuery.company_filter.join(','));
    } else {
        newurl.searchParams.delete('company_filter');
    }

    window.history.pushState({path: newurl.href}, '', newurl.href);
}

function setSelectedQuery(values: string[], id: FilterIdVacancy) {
    switch (id) {
        case FilterIdVacancy.Experience:
            selectQuery.experience = values;
            break;
        case FilterIdVacancy.Interest:
            selectQuery.interest = values;
            break;
        case FilterIdVacancy.JobType:
            selectQuery.jobtype = values;
            break;
        case FilterIdVacancy.Company:
            selectQuery.company_filter = values;
            break;
    }

    const filterConfig = filtersConfiguration.find(filter => filter.id === id);
    if (filterConfig) {
        filterConfig.selected = values;
    }
    updateUrl();
}

// Reset filters
function clearAllFilters() {
    selectQuery.experience = [];
    selectQuery.interest = [];
    selectQuery.jobtype = [];
    selectQuery.company_filter = [];

    filtersConfiguration.forEach(filterConfig => {
        filterConfig.selected = [];
    });
}

// Non-mutually exclusive filtered vacancies (AKA making sure a filter is not selectable when no results occur within that selection)
const nonMutuallyExclusiveFilteredVacancies = (FilterIdVacancyToExclude: FilterIdVacancy): Vacancy[] => {
    const experienceFilter = FilterIdVacancyToExclude !== FilterIdVacancy.Experience ? selectQuery.experience : [];
    const interestFilter = FilterIdVacancyToExclude !== FilterIdVacancy.Interest ? selectQuery.interest : [];
    const jobTypeFilter = FilterIdVacancyToExclude !== FilterIdVacancy.JobType ? selectQuery.jobtype : [];
    const companyFilter = FilterIdVacancyToExclude !== FilterIdVacancy.Company ? selectQuery.company_filter : [];

    const filtered = vacancies.items
        .filter((item: VacancyOrMedia): item is Vacancy => !item.isMediaContent)  // Only keep Vacancy items
        .filter((vacancy: Vacancy) => {
            if (experienceFilter.length > 0 && (!vacancy.taxonomies || !vacancy.taxonomies.experience.some(experience => experienceFilter.includes(experience.name)))) {
                return false;
            }

            if (interestFilter.length > 0 && (!vacancy.taxonomies || !vacancy.taxonomies.interest.some(interest => interestFilter.includes(interest.name)))) {
                return false;
            }

            if (jobTypeFilter.length > 0 && (!vacancy.taxonomies || !vacancy.taxonomies.jobtype.some(jobtype => jobTypeFilter.includes(jobtype.name)))) {
                return false;
            }
            
            if (companyFilter.length > 0 && (!vacancy.taxonomies || !Array.isArray(vacancy.acf.company) || !vacancy.acf.company.some(company => companyFilter.includes(company)))) {
                return false;
            }

            return true;
        });
    return filtered;
};

// Filters configuration
const filters = computed(() => {
    return filtersConfiguration.map(filterConfig => {
        return {
            id: filterConfig.id,
            label: filterConfig.label,
            options: [...new Set([...filterConfig.optionsProvider(), ...filterConfig.selected])], // Merge current options with selected options
            selected: filterConfig.selected,
            isEmpty: filterConfig.optionsProvider().length === 0 && filterConfig.selected.length === 0 // Define an isEmpty property
        }
    })
});

// Only show certain amount of items
const visibleVacancies = computed(() => {
    return filteredVacancies.value.slice(0, maxVisibleItems.value) as VacancyOrMedia[];
});

// 'Load' more vacancies
function loadMore() {
    maxVisibleItems.value += 2;
}

function asMediaContent(item: VacancyOrMedia): MediaContent {
    return item as MediaContent;
}

function isMediaContentEmpty(mediaContent: MediaContent) {
    return !mediaContent.title && !mediaContent.text && !mediaContent.image && !mediaContent.label && !mediaContent.link;
}

watch(() => selectQuery, () => {
    filteredVacancies.value;
});

onMounted(() => {

    if (el) {
        // Set data attributes for wordpress translations
        languageCode.value = el.getAttribute('data-language') || '';
        customTitle.value = el.getAttribute('data-custom-title') || '';
        jobOppertunities.value = el.getAttribute('data-job-oppertunities') || '';
        jobOppertunity.value = el.getAttribute('data-job-oppertunity') || '';
        applyText.value = el.getAttribute('data-apply-text') || '';
        resetText.value = el.getAttribute('data-reset') || '';
        resultsLoadingText.value = el.getAttribute('data-result-loading') || '';
        resultsMore.value = el.getAttribute('data-results-more') || '';
        noResultsText.value = el.getAttribute('data-no-results') || '';
        filterLabelInterest.value = el.getAttribute('data-filter-interest') || '';
        filterLabelType.value = el.getAttribute('data-filter-type') || '';
        filterLabelExperience.value = el.getAttribute('data-filter-experience') || '';
        filterLabelCompany.value = el.getAttribute('data-filter-company') || '';
        ctaTitle.value = el.getAttribute('data-cta-title') || '';
        ctaText.value = el.getAttribute('data-cta-text') || '';
        ctaImage.value = el.getAttribute('data-cta-image') || '';
        ctaImageAlt.value = el.getAttribute('data-cta-image-alt') || '';
        ctaLabel.value = el.getAttribute('data-cta-label') || '';
        ctaUrl.value = el.getAttribute('data-cta-url') || '';
        mediaContentTitle1.value = el.getAttribute('data-media-content-title-1') || '';
        mediaContentText1.value = el.getAttribute('data-media-content-text-1') || '';
        mediaContentImage1.value = el.getAttribute('data-media-content-image-1') || '';
        mediaContentImageAlt1.value = el.getAttribute('data-media-content-image-alt-1') || '';
        mediaContentLabel1.value = el.getAttribute('data-media-content-label-1') || '';
        mediaContentUrl1.value = el.getAttribute('data-media-content-url-1') || '';
        mediaContentTitle2.value = el.getAttribute('data-media-content-title-2') || '';
        mediaContentText2.value = el.getAttribute('data-media-content-text-2') || '';
        mediaContentImage2.value = el.getAttribute('data-media-content-image-2') || '';
        mediaContentImageAlt2.value = el.getAttribute('data-media-content-image-alt-1') || '';
        mediaContentLabel2.value = el.getAttribute('data-media-content-label-2') || '';
        mediaContentUrl2.value = el.getAttribute('data-media-content-url-2') || '';

        // Set filter config (useful for reusing the filter component)
        filtersConfiguration.push(
            {
                id: FilterIdVacancy.Experience,
                label: filterLabelExperience.value, 
                optionsProvider: () => {
                    const vacancies = nonMutuallyExclusiveFilteredVacancies(FilterIdVacancy.Experience);
                    return Array.from(new Set(vacancies.flatMap((vacancy: Vacancy) => vacancy.taxonomies?.experience?.map(l => l.name) ?? [])))
                },
                selected: newParamExperience
            },
            {
                id: FilterIdVacancy.JobType,
                label: filterLabelType.value, 
                optionsProvider: () => {
                    const vacancies = nonMutuallyExclusiveFilteredVacancies(FilterIdVacancy.JobType);
                    return Array.from(new Set(vacancies.flatMap((vacancy: Vacancy) => vacancy.taxonomies?.jobtype?.map(l => l.name) ?? [])))
                },
                selected: newParamJobType
            },            
            {
                id: FilterIdVacancy.Interest,
                label: filterLabelInterest.value, 
                optionsProvider: () => {
                    const vacancies = nonMutuallyExclusiveFilteredVacancies(FilterIdVacancy.Interest);
                    return Array.from(new Set(vacancies.flatMap((vacancy: Vacancy) => vacancy.taxonomies?.interest?.map(l => l.name) ?? [])))
                },
                selected: newParamInterest
            },
            {
                id: FilterIdVacancy.Company,
                label: filterLabelCompany.value,
                optionsProvider: () => {
                    const vacancies = nonMutuallyExclusiveFilteredVacancies(FilterIdVacancy.Company);
                    const companies = Array.from(new Set(vacancies.flatMap((vacancy: Vacancy) => vacancy.acf?.company ?? [])));
                    return companies.filter((company: string) => company !== '');
                },
                selected: newParamCompany
            }
        )
    }

    loadVacancies()
    return filtersConfiguration;
})

</script>