<template>
    <label class="filter__label">
        <input
            class="filter__input"
            v-model="searchQuery"
            :placeholder="computedPlaceholder"
        />
    </label>
</template>
  
<script setup lang="ts">
import { ref, computed, watch } from 'vue';
  
let searchQuery = ref('');

const props = defineProps({
    searchQueryText: { 
        type: String, 
        required: false 
    },
    placeholder: {
        type: String,
        required: true
    }
})

let computedPlaceholder = computed(() => {
    return props.searchQueryText ? props.searchQueryText : props.placeholder;
});
  
watch(
    () => searchQuery.value,
    (newValue, oldValue) => {
        act(newValue);
    }
);
  
const emit = defineEmits(['input'])
const act = (id:string) => {
    emit('input', id);
}
</script>